.copy-code {
    overflow: hidden;
    background-color: #fff7fb;
    border: 1px solid #db7100;
    cursor: pointer;
    padding: 4px;
    font-family: "Courier New";
    border-radius: 5px;
}

.copy-code:hover {
    background-color: #fff2f5;
}

.copy-code-code {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
