.list-wrapper {
  width: 20%;
  min-width: 200px;
  height: 350px;
  margin: 25px;
  overflow: hidden;
  border: 1px solid #b8c6e7;
  border-top: 2px solid #b8c6e7;
  border-radius: 7px;
}

@media screen and (max-width: 800px) {
  .list-wrapper {
    flex-basis: 30%; } }

@media screen and (max-width: 640px) {
  .list-wrapper {
    flex-basis: 45%; } }

@media screen and (max-width: 480px) {
  .list-wrapper {
    flex-basis: 90%; } }

.list-header {
  background-color: #f5f8fc;
  height: 25%;
  border-bottom: 1px solid #b8c6e7;
  padding: 5px; }

.list-body {
  background-color: white;
  height: 75%;
  overflow-y: auto;
  border-radius: 0 0 7px 7px; }

.list-item {
  height: 20%;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #b8c6e7; }
  .list-item .subtitle {
    color: rgba(0, 0, 0, 0.5); }

.list-item:hover {
  background-color: #fffbf2; }
