.ServiceActionsTimeline {
  position: relative;
  padding: 0 40px;
  overflow: visible;
}

.ServiceActionsTimeline .slick-slider {
  flex-grow: 1 !important;
  overflow: hidden !important;
}

.ServiceActionsTimeline::before {
  top: 46px;
  left: -20px;
  width: calc(100% + 40px);
  display: block;
  content: "";
  height: 2px;
  background-color: #d0d0d3;
  position: absolute;
}

.ServiceActionsTimeline .slick-prev {
    top: 48px;
    left: -13px;
    z-index: 1; }
  .ServiceActionsTimeline .slick-next {
    top: 48px;
    right: -13px;
    z-index: 1; }
  .ServiceActionsTimeline .slick-list, .ServiceActionsTimeline .slick-list {
    overflow: visible; }
