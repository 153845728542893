.messenger-master-header-content {
  font-size: 19px;
  line-height: 1.15;
  min-height: 35px;
  color: #656565;
  background-color: #fff;
  position: relative;
  border-bottom: 1px solid #cfdbe2;
  padding: 15px 15px; }

.chat-window-container {
  overflow-y: auto;
  background-image: url("./Rotor_BG_w1400px.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover; }
  .chat-window-container .rce-mbox-body {
    max-width: 600px; }

.rce-container-input .rce-input-textarea {
  height: 41px; }

.add-conversation-button {
  position: fixed;
  bottom: 20px;
  left: 20px; }

.service-action-code-name-label .service-action-code {
  font-weight: bold; }

.service-action-code-name-label .service-action-name {
  font-weight: bold; }

.service-action-update {
  font-style: italic;
  font-size: 0.9em; }

.unread {
  font-weight: bold; }

.font-weight-600 {
  font-weight: 600; }
