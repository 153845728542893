.editable-cell {
  display: flex;
  position: relative; }

.editable-cell > .editable-cell-input-wrapper {
  display: inline-block;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row; }

.editable-cell-input-wrapper > input {
  flex-grow: 1; }

.editable-cell-text-wrapper {
  display: inline-block;
  padding: 4px 24px 5px 5px; }

.editable-cell-text-wrapper.clickable:hover {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-color: #ee8126;
  cursor: pointer; }

.editable-cell-icon,
.editable-cell-icon-check {
  padding: 2px;
  width: 20px;
  cursor: pointer;
  align-self: center; }

.editable-cell-icon {
  line-height: 18px;
  display: none; }

.editable-cell-icon-check {
  line-height: 18px; }

.editable-cell:hover .editable-cell-icon {
  display: inline-block; }

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #ee8126; }

.editable-add-btn {
  margin-bottom: 8px; }
