span.LabelMarkdown > * {
    margin: 0;
}

span.ant-input-group-addon {
    text-align: left;
}

span.ant-input-group-addon .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector, .ant-input-group-addon:last-child .ant-select .ant-select-selector {
    border: none;
}
