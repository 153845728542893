/* Media Queries */
.date-section + .log-card-wrapper {
  margin-top: 0; }

.log-card-wrapper {
  position: relative;
  display: block;
  padding: 25px 25px 0 25px;
  width: 100%;
  left: 0;
  margin-top: -90px;
  margin-bottom: 16px; }
  @media (min-width: 720px) {
    .log-card-wrapper {
      width: 50%;
      padding: 40px 40px 0 40px; } }
  .log-card-wrapper .log-icon {
    display: none; }
  @media (min-width: 720px) {
    .log-card-wrapper:nth-child(odd) .log-icon {
      display: block;
      position: absolute;
      z-index: 2;
      left: calc(100% - 20px);
      top: 35px; }
    .log-card-wrapper:nth-child(odd) .log-head.success::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #22bb33;
      left: 100%; }
    .log-card-wrapper:nth-child(odd) .log-head.warning::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #f0ad4e;
      left: 100%; }
    .log-card-wrapper:nth-child(odd) .log-head.error::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #bb2124;
      left: 100%; }
    .log-card-wrapper:nth-child(odd) .log-head.information::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #607D8B;
      left: 100%; } }
  .log-card-wrapper:nth-child(even) {
    left: 0; }
    @media (min-width: 720px) {
      .log-card-wrapper:nth-child(even) {
        left: 50%; }
        .log-card-wrapper:nth-child(even) .log-icon {
          display: block;
          position: absolute;
          z-index: 2;
          left: -20px;
          top: 35px; }
        .log-card-wrapper:nth-child(even) .log-head.success::after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid #22bb33;
          right: 100%; }
        .log-card-wrapper:nth-child(even) .log-head.warning::after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid #f0ad4e;
          right: 100%; }
        .log-card-wrapper:nth-child(even) .log-head.error::after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid #bb2124;
          right: 100%; }
        .log-card-wrapper:nth-child(even) .log-head.information::after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid #607D8B;
          right: 100%; } }
  .log-card-wrapper .fa-20px {
    font-size: 20px; }
  .log-card-wrapper .log-card {
    position: relative;
    box-shadow: 0 1px 15px 4px rgba(0, 0, 0, 0.05);
    z-index: 2; }
    .log-card-wrapper .log-card .log-head {
      position: relative;
      align-items: center;
      padding: 5px 10px 5px 10px; }
      .log-card-wrapper .log-card .log-head .log-title {
        font-size: 1.2em;
        color: white; }
    .log-card-wrapper .log-card .log-body {
      padding: 10px;
      background-color: #fff; }
      .log-card-wrapper .log-card .log-body .log-content {
        font-size: 1em; }
    .log-card-wrapper .log-card .log-footer {
      background-color: #f6f6f6;
      padding: 5px 10px 5px 10px;
      font-size: 0.8em; }
      .log-card-wrapper .log-card .log-footer .log-user-date {
        padding-left: 7px; }
