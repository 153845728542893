.resetMargin {
    margin: 0;
}

.autoHeight {
    height: auto;
}

.ConfirmButton {
    padding: 0 5px;
}
