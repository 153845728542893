.content-header-component {
  position: relative;
  border-bottom: 1px solid #cfdbe2;
  padding: 8px 16px; }

.content-header-component .content-header-content {
  font-size: 24px;
  line-height: 1.15;
  min-height: 43px;
  color: #656565;
  background-color: #fff; }

.content-header-component .content-header-content .content-header-headline {
  vertical-align: middle; }

.content-header-component.size-small .content-header-content {
  font-size: 17px;
  line-height: 0.9;
  min-height: 20px; }
