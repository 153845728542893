.attachment-box {
  font-size: 0.9em; }
  .attachment-box .new-value {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #efeff2;
    border-radius: 5px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }
  .attachment-box .old-value {
    margin-bottom: 10px;
    border: 1px solid #efeff2;
    border-radius: 5px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }
  .attachment-box .left-side {
    padding: 6px;
    border-right: 1px solid #efeff2; }
  .attachment-box .right-side {
    padding: 6px; }
  .attachment-box .file-preview-flex {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 6px;
    border: 1px solid #efeff2;
    border-radius: 5px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    .attachment-box .file-preview-flex .file-icon {
      font-size: 3em;
      padding-left: 10px; }
  .attachment-box .link-box {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 6px;
    border: 1px solid #efeff2;
    border-radius: 5px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }

.attachment-box .ticket-props {
  display: flex;
  justify-content: space-between;
}
