.ServiceTicketDashboardHeader {
  background: white;
  padding: 8px 16px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
  .ServiceTicketDashboardHeader > .top-row {
    display: flex;
    flex-direction: row; }
    .ServiceTicketDashboardHeader > .top-row > .title {
      font-size: 24px;
      padding: 4px 8px 4px 0;
      font-weight: 500;
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      align-items: center; }
      .ServiceTicketDashboardHeader > .top-row > .title input {
        font-size: 24px;
        height: 38px; }
    .ServiceTicketDashboardHeader > .top-row > .tools > button, .ServiceTicketDashboardHeader > .top-row > .tools > a {
      margin: 0 4px; }
