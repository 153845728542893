/* Media Queries */
.date-section + .item-card-wrapper {
  margin-top: 0; }

.item-card-wrapper {
  position: relative;
  display: block;
  padding: 25px 25px 0 25px;
  width: 100%;
  left: 0;
  margin-top: -90px;
  margin-bottom: 16px; }
  @media (min-width: 720px) {
    .item-card-wrapper {
      width: 50%;
      padding: 40px 40px 0 40px; } }
  .item-card-wrapper .item-icon {
    display: none; }
  @media (min-width: 720px) {
    .item-card-wrapper:nth-child(odd) .item-icon {
      display: block;
      position: absolute;
      z-index: 2;
        left: calc(100% - 20px);
      top: 35px; } }
  .item-card-wrapper:nth-child(even) {
    left: 0; }
    @media (min-width: 720px) {
      .item-card-wrapper:nth-child(even) {
        left: 50%; }
        .item-card-wrapper:nth-child(even) .item-icon {
          display: block;
          position: absolute;
          z-index: 2;
          left: -20px;
            top: 35px;
        }
    }
  .item-card-wrapper .fa-20px {
    font-size: 20px; }
  .item-card-wrapper .item-card {
    position: relative;
    box-shadow: 0 1px 15px 4px rgba(0, 0, 0, 0.05);
    z-index: 2; }
    .item-card-wrapper .item-card .item-head {
      position: relative;
      align-items: center;
      padding: 5px 10px 5px 10px; }
      .item-card-wrapper .item-card .item-head .item-title {
        font-size: 1.2em;
        color: white; }
    .item-card-wrapper .item-card .item-body {
      padding: 10px;
      background-color: #fff; }
      .item-card-wrapper .item-card .item-body .item-content {
        font-size: 1em; }
    .item-card-wrapper .item-card .item-footer {
      background-color: #e9e9e9;
      padding: 5px 10px 5px 10px;
      font-size: 0.8em; }
      .item-card-wrapper .item-card .item-footer .item-user-date {
        padding-left: 7px; }
