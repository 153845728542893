.StatisticsDashboardPanels {
    padding: 10px 5%;
    gap: 10px 5%;
    flex-wrap: wrap;
    justify-content: center;
}

.StatisticsDashboardPanels > * {
    flex: 1;
    min-width: 190px;
    max-width: 240px;
}