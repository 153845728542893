.loader {
  display: block;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  text-align: center; }
  .loader.mask {
    background: rgba(255, 255, 255, 0.9); }
  .loader.absolute {
    position: absolute; }
  .loader.small {
    min-height: 30px; }
  .loader.large {
    min-height: 100px; }
  .loader.fullScreen {
    position: fixed;
    width: 100%;
    height: 100%; }
  .loader .wrapper {
    width: 100px;
    height: 100px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around; }
  .loader .inner {
    width: 48px;
    height: 48px;
    margin: 0 auto;
    text-indent: -12345px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 1px solid rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    z-index: 100001;
    animation: spinner 600ms infinite linear; }
  .loader .text {
    width: 100px;
    height: 20px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 4px;
    color: #000; }
  .loader.hidden {
    z-index: -1;
    opacity: 0;
    transition: opacity 1s ease 0.5s, z-index 0.1s ease 1.5s; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
