.shadow-panel {

    background-color: white;

    box-shadow: 0px 36px 113px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 36px 113px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 36px 113px 0px rgba(0,0,0,0.1);
}

.shadow-panel-body {
    padding: 20px;
}
