.microservice-menu-button {
    margin-left: 12px;
    padding: 4px 6px;
    border: none;
    color: rgba(0,0,0,.85);
    border-radius: 6px;
    background-color: transparent;
    box-shadow: none;
}

.microservice-menu-button:hover {
    color: rgba(0,0,0,.85);
    background-color: #F0F4F8;
}

.microservice-menu-button:focus {
    color: rgba(0,0,0,.85);
    background-color: #F0F4F8;
}

.popover-content-wrapper {
    margin: 6px 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.popover-user-info {
    display: flex;
    flex-direction: column;
}

.popover-user-info-title {
    font-size: large;
    font-weight: bold;
}

.popover-user-info-subtitle {
    font-size: small;
    color: #515151;
}

.popover-headline {
    font-size: 1rem;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
}
