.ant-tabs-nav {
    height: 100%;
}

.ant-tabs-nav-wrap {
    margin-right: 3px;
}

.tabs-top-padding .ant-tabs-nav-wrap {
    padding-top: 0;
    margin-top: 4px;
}
