.quick-access-item-wrapper {
    margin-bottom: 12px;
    padding: 6px 3px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quick-access-item-wrapper:hover {
    background-color: rgba(0,0,0,.1);
}

.quick-access-item-text {
    color: rgba(0,0,0,.5);
}
