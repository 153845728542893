/* Media Queries */
.timeline-wrapper {
  position: relative;
  margin: auto;
  display: flex;
  flex-flow: column wrap; }
  .timeline-wrapper::after {
    display: none; }
  @media (min-width: 720px) {
    .timeline-wrapper::after {
      display: inline;
      z-index: 1;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50% - 1px);
      border: 1px solid rgba(191, 191, 191, 0.4); } }
  .timeline-wrapper .date-section {
    margin: auto;
    min-width: 150px;
    text-align: center;
    z-index: 2;
    padding: 10px;
    margin-top: 25px;
    background-color: #f8f8f8;
    color: #5f5f5f; }
    @media (min-width: 720px) {
      .timeline-wrapper .date-section {
        margin-top: 40px; } }
