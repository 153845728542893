.service-calendar > .ant-fullcalendar-header > .ant-radio-group {
  display: none;
}

.ant-picker-calendar-header {
  padding: 12px 10px;
}

.ServiceCalendarFilterPanel .ant-picker-calendar .ant-picker-cell-disabled {
  cursor: default;
  pointer-events: auto;
}
