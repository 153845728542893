.infinity-list {
  background: white;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative; }
  .infinity-list.infinity-list-bordered {
    border-radius: 4px;
    border: 1px solid #d9d9d9; }
  .infinity-list .ant-list-item {
    padding: 8px 16px;
    border-bottom: 1px solid #e8e8e8; }
