.login.form .ant-form-item-label {
    display: none;
}

.login .logo {
    margin-top: 20px;
    margin-bottom: 20px;
}

.login button {
    width: 100%
}

.login .passwordResetWrapper {
    align-content: center;
}

.login .mdi-icon {
    vertical-align: sub;
}

.LoginText {
    text-align: center;
    font-size: 13px;
}

.HelpLink {
    text-align: center;
    font-size: 13px;
}