.ant-breadcrumb li::marker {
    display: none;
    font-size: 1px;
}

.ant-breadcrumb ol {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
}

.ant-breadcrumb li {
    display: flex;
    flex-direction: row;
}

.ant-breadcrumb li:last-child .ant-breadcrumb-separator {
    display: none;
}

.ant-breadcrumb li:last-child a {
    color: rgba(0, 0, 0, .85);
}
