.client-suggestion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #cfdbe2;
    border-radius: 7px;
    padding: 15px;
    cursor: pointer;
}

.client-suggestion-item > .client-suggestion-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 15px;
}

.client-suggestion-content > .title {
    font-weight: bold;
}

.client-suggestion-content > .subtitle {
    color: rgba(0,0,0,.45);
}

.client-suggestion-item > .icon {
    margin-top: 6px;
    font-size: 28px;
}
