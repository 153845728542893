.ServiceProviderTabWrapper .ant-tabs-top {
    background: none;
}

.ServiceProviderTabWrapper .ant-tabs-nav {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
}

.ServiceProviderRoleList {
    height: calc(100vh - 205px);
}
