.component-search-list-item .componentType-col, .component-search-header .componentType-col {
  width: 70px; }

.component-search-list-item .name-col, .component-search-header .name-col {
  width: 40%; }

.component-search-list-item .manufacturer-col, .component-search-header .manufacturer-col {
  width: 20%; }

.component-search-list-item .catalogNo-col, .component-search-header .catalogNo-col {
  width: 15%; }

.component-search-list-item .serial-col, .component-search-header .serial-col {
  width: 15%; }

.component-search-list-item .runTime-col, .component-search-header .runTime-col {
  width: 10%; }
