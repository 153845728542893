.workflowboard-list-item-selected .service-ticket-list-item .title-row a {
  color: #cb5b1c; }

.service-ticket-list-item {
  padding: 8px;
  flex: 1; }
  .service-ticket-list-item.clickable:hover {
    background-color: #f8f8f8;
    cursor: pointer; }
  .service-ticket-list-item > div {
    display: block;
    width: 100%; }
  .service-ticket-list-item .title-row {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500; }
    .service-ticket-list-item .title-row a {
      color: #323232; }
      .service-ticket-list-item .title-row a:hover {
        text-decoration: underline; }
    .service-ticket-list-item .title-row > div {
      padding-right: 8px; }
  .service-ticket-list-item .component-row {
    margin: 4px 0;
    line-height: 14px; }
  .service-ticket-list-item .client-row {
    font-size: 12px; }
  .service-ticket-list-item .service-action-row {
    font-size: 12px; }
