.CollapseContent {
    background: var(--white);
}

.CollapseContent .ant-collapse-header {
    display: flex;
    align-items: center;
}

.CollapseBadge {
    background-color: #52c41a;
}
