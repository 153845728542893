.StatisticsPanelWrapper {
    border-radius: 10px;
    background-color: #f8f8f8;
    font-size: 14px;
}

.StatisticsPanelWrapper.loading .StatisticsPanelIcon {
    width: 60px;
    height: 55px;
    border-radius: 8px;
    margin: 10px 15px;
    animation : shimmer 2s infinite;
    background: linear-gradient(to right, #efefef 4%, #e7e7e7 25%, #efefef 36%);
    background-size: 1000px 100%;
}

.StatisticsPanelWrapper.loading .StatisticsPanelCount {
    width: 75px;
    height: 28px;
    border-radius: 8px;
    margin: 0 10px 4px 0;
    animation : shimmer 2s infinite;
    background: linear-gradient(to right, #efefef 4%, #e7e7e7 25%, #efefef 36%);
    background-size: 1000px 100%;
}

.StatisticsPanelWrapper.loading .StatisticsPanelTitle {
    width: 110px;
    height: 18px;
    border-radius: 8px;
    margin: 2px 10px 0 0;
    animation : shimmer 2s infinite;
    background: linear-gradient(to right, #efefef 4%, #e7e7e7 25%, #efefef 36%);
    background-size: 1000px 100%;
}

.StatisticsPanelIcon {
    padding: 0 20px;
    font-size: 46px;
}

.StatisticsPanelTextWrapper {
    flex-direction: column;
    align-self: center;
}

.StatisticsPanelCount {
    line-height: normal;
    font-size: 24px;
    font-weight: bold;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}